<template>
  <AuthenticationRequired>
    <router-view />
  </AuthenticationRequired>
</template>

<script>
  import AuthenticationRequired from '@/components/auth/AuthenticationRequired.vue'

  export default {
    name: 'App',
    components: { AuthenticationRequired },

    watch: {
      '$route': {
        handler(to) {
          document.title = to.meta.title || 'Dream'
        },

        immediate: true,
      }
    },
  }
</script>

<style lang="scss">
@import "@/assets/style/icons.scss";
@import "@/assets/style/projects.scss";
@import "@/assets/fontawesome/css/all.min.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jura:wght@600&display=swap');

html {
  overflow-x: hidden;

  &.locked {
    overflow: hidden;
  }
}

body {
  height: 100vh;
  background: #1E272E !important;

  // font-family: 'Roboto';
  --bs-body-font-family: 'Roboto';
}

body.report-page {
  height: 100%;
}

#app {
  height: 100%;

  & > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.app{
  height: 100vh;
}

.schedule {
  padding-top: 64px;
  height: 100%;

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  &__nav {
    position: absolute;
    top: 64px;
    left: 0;
    padding: 5px 20px;
    z-index: 11;
  }
}

.moving * {
  cursor: move !important;
}

.loading {
  position: relative;

  @keyframes loading {
    0% {
      background-color: hsl(206, 15%, 22%);
    }

    100% {
      background-color: hsl(206, 15%, 25%);
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    border-radius: 12px;
    animation: loading 1s linear infinite alternate;
  }
}
</style>
